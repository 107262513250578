import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"


/* Import Page Component(s) */
import ProfilePage from "~components/pages/profile/profilePage"





/* Import Local Style(s) */
import "./apply.scss"

const Thankyou = ({ location }) => {

  return (
    <ProfilePage title="Apply for VIP Status" header={"Apply for VIP Status"} location={{state: {prevUrlPath: '/'}}}>
      <div className="vip-thankyou">
        <p>Your VIP application has been received.<br />On approval, you will receive your VIP invitation via email.</p>        
      </div>
      <div className="links">
        <Link to="/vip">Login</Link>
      </div>
    </ProfilePage>
  )
}

export default Thankyou
